import { FC, ReactElement } from 'react'
import { CopyButton } from '../../button'
import styles from './BankAccountDetails.m.less'

interface IPaymentTargetProps {
    textToCopy: string
    textNode: ReactElement
}
export const PaymentTarget: FC<IPaymentTargetProps> = ({ textToCopy, textNode }) => {
    return (
        <div className={styles.PaymentTargetRow}>
            <span className={styles.PaymentTargetRowLabel}>Назначение платежа</span>
            <div className={styles.PaymentTargetRowValueContainer}>
                <span className={styles.PaymentTargetRowValue}>{textNode}</span>
                <CopyButton text={textToCopy} />
            </div>
        </div>
    )
}
