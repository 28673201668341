import { IBankDetails } from '@dltru/dfa-models'
import { RequisitesType } from '../../Requisites'

export const getRequisitesPartBank = (bankDetails: IBankDetails): RequisitesType[] => [
    {
        label: 'БИК банка получателя',
        value: bankDetails?.bic ?? '',
    },
    {
        label: 'Номер счета',
        value: bankDetails?.account_number ?? '',
    },
    {
        label: 'Банк получателя',
        value: bankDetails.bank ?? '',
    },
    {
        label: 'Корр счёт',
        value: bankDetails?.corr_account_number ?? '',
    },
]

export const requisitesPartBankToString = (bankDetails: IBankDetails): string =>
    getRequisitesPartBank(bankDetails)
        .map((item: RequisitesType) => `${item.label}: ${item.value}`)
        .join(';')

export const getRequisitesPartRecepient = (
    recipient: string,
    recipientInn: string,
): RequisitesType[] => [
    {
        label: 'Получатель',
        value: recipient,
    },
    {
        label: 'ИНН',
        value: recipientInn,
    },
]
