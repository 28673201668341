import { FC, useCallback, useMemo } from 'react'
import { Box } from '../../Box'
import { Button } from '../../button'
import { MiniModal } from '../../components/Modal'
import { Divider } from '../../Divider'
import { Copy } from '../../Icons'
import { Requisites } from '../../Requisites'
import { PaymentTarget } from './PaymentTarget'
import { IBankAccountDetailsModal } from './types'
import {
    getRequisitesPartBank,
    getRequisitesPartRecepient,
    requisitesPartBankToString,
} from './utils'

export const BankAccountDetailsModal: FC<IBankAccountDetailsModal> = ({
    isModalVisible,
    setIsModalVisible,
    title,
    bodyText,
    paymentTargetText,
    paymentTarget,
    recipient,
    recipientInn,
    bankDetails,
    shift,
}) => {
    const requisitesPartBank = useMemo(() => {
        return getRequisitesPartBank(bankDetails)
    }, [])

    const requisitesPartRecepient = useMemo(() => {
        return getRequisitesPartRecepient(recipient, recipientInn)
    }, [])

    const onCopyHandler = useCallback(() => {
        const textToCopy = `${requisitesPartBankToString(
            bankDetails,
        )} Получатель: ${recipient}; Получатель ИНН: ${recipientInn}; Назначение платежа: ${paymentTargetText};`
        // navigator.clipboard не работает если не https web.dev/articles/async-clipboard?hl=ru
        if (navigator.clipboard) {
            navigator.clipboard.writeText(textToCopy).then()
        } else {
            const textarea = document.createElement('textarea')
            textarea.textContent = textToCopy
            textarea.style.position = 'fixed'
            document.body.appendChild(textarea)
            textarea.select()
            try {
                document.execCommand('copy')
            } catch (e) {
                console.error(e)
            } finally {
                document.body.removeChild(textarea)
            }
        }
    }, [])

    const handleCancel = useCallback(() => {
        setIsModalVisible(false)
    }, [])

    return (
        <>
            <MiniModal
                visible={isModalVisible}
                onCancel={handleCancel}
                width={600}
                closable
                destroyOnClose
                style={{ left: shift ? 240 : 0 }}
            >
                <div>
                    <Box direction="row" align="center" margin={[8, 0]}>
                        <div className="ant-modal-title">{title}</div>
                    </Box>

                    <Box margin={[0, 0]}>
                        <div className="modal-content-shift">&nbsp;</div>
                        <p className="modal-content-text">{bodyText}</p>
                    </Box>
                    <Box margin={[0, 24]}>
                        <Requisites requisites={requisitesPartBank} copyEnabled />
                        <Divider margin={[16, 0]} />
                        <Requisites requisites={requisitesPartRecepient} copyEnabled />
                        <Divider margin={[16, 0, 24, 0]} />
                        {paymentTargetText && paymentTarget ? (
                            <PaymentTarget
                                textToCopy={paymentTargetText}
                                textNode={paymentTarget}
                            />
                        ) : null}
                    </Box>
                    <Box
                        className="ant-modal-footer ant-modal-footer-no-border"
                        padding={[0, 24, 8, 24]}
                        direction="row"
                        justify="left"
                    >
                        <Button icon={<Copy />} borderRadius={12} onClick={onCopyHandler}>
                            Скопировать все реквизиты
                        </Button>
                    </Box>
                </div>
            </MiniModal>
        </>
    )
}
